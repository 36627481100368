import {graphql} from 'gatsby';
import * as React from 'react';

import renderContentful from '../contentful/ComponentIndex';
import ContentfulProductGroup from '../contentful/productGroup/ContentfulProductGroup';
import {ProductGroupPageContentModel} from '../contentful/productGroup/ProductGroupContentModel';
import PageTemplate from './PageTemplate';

export const indexPageQuery = graphql`
  query($id: String!) {
    contentfulProductGroup(id: {eq: $id}) {
      ...BaseInfoFragment
      ...ProductGroupPageFragment
    }
  }
`;

interface Props {
  data: Data;
  location: any;
}

interface Data {
  contentfulProductGroup: ProductGroupPageContentModel;
}

const ProductGroupPageTemplate: React.FunctionComponent<Props> = ({
  data,
  location,
}) => (
  <PageTemplate
    {...data.contentfulProductGroup}
    render={settings => (
      <>
        <ContentfulProductGroup
          baseModel={data.contentfulProductGroup}
          pageSettings={settings}
          renderAs={'section'}
        />
        {renderContentful(
          data.contentfulProductGroup.pageSections,
          settings,
          location.state
        )}
      </>
    )}
  />
);

export default ProductGroupPageTemplate;
